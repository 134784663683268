@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
/* ********* Global Color styling for the full app ********* */
:root {
  --main-bg-color: #f3f2ef;
  --Grey1: #333333;
  --Grey2: #4f4f4f;
  --Grey3: #828282;
  --Grey4: #bdbdbd;
  --Grey5: #e0e0e0;
  --Grey5--hover: #c5c5c5;
  --Blue: #4a9ef7;
  --Blue-hover: #1f86f4;
  --Container-grey: #f0f0f0;
  --Container-grey-hover: #d1d1cf;
  --Container-text: #707683;
  --Secondary-green: #14a6a6;
  --Secondary-green--hover: #0d7979;

  --color-grey-50: #f7fafc;
  --color-grey-100: #e3e8ee;
  --color-grey-dark-100: #7a8394;
  --color-grey-200: #c1c9d2;
  --color-grey-300: #a3acb9;
  --color-grey-400: #8792a2;
  --color-grey-500: #697386;
  --color-grey-600: #59617b;
  --color-grey-700: #3c4257;
  --color-grey-800: #2a2f45;
  --color-grey-900: #1a1f36;
}
/* ****************************************** */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/** Custom scrollbar for all 'Overflows Y and X' 
[Always reuse the className="scrollbarY--custom"] on div element
if scrollbar is needed*/
.scrollbarY--custom::-webkit-scrollbar {
  width: 0.6em;
  height: 0.6rem;
  border-radius: 0px 20px 0px 0px;
}

.scrollbarY--custom::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

.scrollbarY--custom::-webkit-scrollbar-thumb {
  background-color: rgb(182, 182, 182);
  border-radius: 10px 20px 10px 10px;
}

body {
  font-family: 'Poppins', sans-serif !important;
}

.MuiTypography-body1 {
  font-family: 'Poppins', sans-serif !important;
}
.react-datepicker {
  font-family: 'Poppins', sans-serif !important;
}
.MuiTypography-body2,
.MuiChip-label,
.MuiTypography-body1 {
  font-family: 'Poppins', sans-serif !important;
  font-size: 1rem !important;
}

.fc .fc-toolbar-title {
  font-size: 15px !important;
  margin: 0 auto;
  text-align: center;
}

/* .MuiAutocomplete-root .MuiInput-underline:before,
.MuiAutocomplete-root .MuiInput-underline:after {
   border-bottom: 0px solid rgba(0, 0, 0, 0)!important;
}  */

.fc .fc-timegrid-slot {
  height: 102px !important;
  background: #fff !important;
}

.switch.btn {
  width: 100%;
}

button:focus {
  outline: 0px;
}

:focus {
  outline: 0px;
}

.MuiFormLabel-asterisk {
  color: red;
}

/* .fc-theme-standard td,
.fc-theme-standard th {
  border: 0px solid #ddd !important;
} */

.fc-direction-ltr .fc-timegrid-col-events {
  margin: 0 4.5% 0 5px !important;
}

.fc-timegrid-event {
  border-radius: 15px !important;
}

/*.MuiCardContent-root:last-child {*/

/*    padding-bottom: 0px!important;*/

/*}*/

.MuiAutocomplete-clearIndicator {
  display: none !important;
}

.fc-theme-standard .fc-scrollgrid {
  border: 0px solid #ddd !important;
  background: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 1px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
}

.fc-license-message {
  display: none;
}

.fc .fc-timegrid-axis-cushion,
.fc .fc-timegrid-slot-label-cushion {
  padding: 0 20px !important;
}

.fc .fc-timegrid-col.fc-day-today {
  background: #fff !important;
}

.fc-col-header-cell.fc-resource {
  padding: 25px;
}

.calender-section-two .MuiInput-underline:before,
.calender-section-two .MuiInput-underline:after {
  border-bottom: 0px solid rgba(0, 0, 0, 0) !important;
}

.fc-col-header-cell.fc-resource .fc-col-header-cell-cushion {
  color: #fff;
  background: #7d89d9;
  padding: 10px 15px !important;
  border-radius: 30px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 1px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
  /*box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);*/
}

.MuiTableCell-body {
  text-align: center;
}
/* .makeStyles-tableStyle-76 .MuiTableCell-body {
    text-align: center!important;
} */
.react-datepicker__today-button {
  background: #f0f0f0;
  border: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: none;
  width: 100px;
  margin: 0 10px;
}

.react-datepicker__header {
  background-color: #7d89d9 !important;
  border-radius: 0 !important;
}

.react-datepicker__current-month {
  color: white !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #7d89d9 !important;
  color: #fff;
}

.react-datepicker__day-name {
  color: #fff !important;
}

.react-datepicker__today-button {
  margin-bottom: 10px;
}

.react-datepicker__input-container {
  display: none !important;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__navigation--next {
  border-left-color: #fff !important;
}

.react-datepicker__navigation--previous {
  border-right-color: #fff !important;
}

/* 
.MuiChip-root {
    color: #fff!important;
    height: 50px!important;
  
    font-family: 'Poppins', sans-serif !important;
    border-radius: 5px!important;
    background-color: #7D89D9 !important;
    font-size: 1rem!important;
} */

.MuiTable-root {
  overflow: 'auto';
}

.searchPatient .MuiTableCell-body {
  text-align: left !important;
}

.date-section-one li {
  /* height: 105px !important; */
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.MuiGrid-grid-xs-2 {
  flex-grow: 0;
  max-width: 14.6% !important;
  flex-basis: 14.6% !important;
}
.MuiGrid-grid-xs-6 {
  flex-grow: 0;
  max-width: 52% !important;
  flex-basis: 52% !important;
}
.accordion .subheading {
  font-size: 0.8rem !important;
}
/* .makeStyles-drawerOpen-44 {
    width: 308px !important;
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
} */

@media all and (max-width: 2200px) and (min-width: 1875px) {
  .MuiGrid-grid-xs-2 {
    flex-grow: 0;
    max-width: 12.6% !important;
    flex-basis: 12.6% !important;
  }

  .MuiGrid-grid-xs-6 {
    flex-grow: 0;
    max-width: 54% !important;
    flex-basis: 54% !important;
  }
  /* 
    .drawerStyle {
        width: 410px !important;
    } */
}
.MuiTableCell-head,
.MuiTableCell-body {
  color: rgba(0, 0, 0, 0.75) !important;
  font-size: 1rem !important;
}

@media all and (max-width: 1686px) and (min-width: 900px) {
  .calender-section-two .MuiChip-root {
    height: 31px !important;
  }
  .accordion .subheading {
    font-size: 0.7rem !important;
  }

  .MuiTypography-body2,
  .MuiChip-label,
  .MuiTypography-body1 {
    font-family: 'Poppins', sans-serif !important;
    font-size: 0.8rem !important;
  }

  .ptIcon {
    height: 20px !important;
    width: 20px !important;
    color: #fff;
  }

  .section-three .MuiSvgIcon-root {
    padding-right: 6px !important;
  }

  .section-three .MuiCard-root .MuiCard-root {
    padding: 16px 0px !important;
  }
  .MuiGrid-spacing-xs-2 > .MuiGrid-item {
    padding: 6px !important;
  }
  .accordion .MuiInputBase-input {
    font-size: 0.85rem;
  }
  .accordion .MuiFormLabel-root {
    font-size: 0.85rem;
  }
  .fc .fc-toolbar-title {
    font-size: 0.8rem !important;
  }
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0.5em !important;
  }
  .section-three .MuiCard-root {
    margin-bottom: 6px !important;
  }
  .fc-col-header-cell.fc-resource .fc-col-header-cell-cushion {
    font-size: 12px !important;
    padding: 5px 12px !important;
  }
  .fc-col-header-cell.fc-resource {
    padding: 11px !important;
  }
  .section-three .MuiCard-root .MuiCard-root {
    margin-bottom: 0px;
    padding: 15px 0px;
  }
  .calender-section-two {
    padding: 7px !important;
  }

  .MuiGrid-grid-xs-2 {
    flex-grow: 0;
    max-width: 16.666667% !important;
    flex-basis: 16.666667% !important;
  }
  .MuiGrid-grid-xs-6 {
    flex-grow: 0;
    max-width: 50% !important;
    flex-basis: 50% !important;
  }

  .drawerStyle {
    width: 150px !important;
  }
  .date-section-one li {
    height: 85px !important;
  }
  .MuiTableCell-head,
  .MuiTableCell-body {
    color: rgba(0, 0, 0, 0.75) !important;
    font-size: 0.85rem !important;
  }
}
/* .MuiTableCell-root {
    padding: 16px 0!important;
  }
  .MuiCollapse-entered {
    border-top: 1px solid #ccc;
  } */
.MuiTab-wrapper {
  text-transform: none !important;
  font-size: 0.99rem;
  font-weight: 600;
}
.MuiTypography-h5 {
  font-size: 1rem !important;
  font-weight: 500 !important;
}


.btn--white{
  color: #fff !important;
}
.clinicLogin {
  height: 100vh;
  font-family: 'Montserrat', sans-serif;
  position: relative;
  background: #fff;
}

.clinicLogin__header {
  min-height: 80px;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--Grey5);
}

.clinicLogin__header__bar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 90%;
}

.clinicLogin__header__logo {
  width: 165px;
}

.clinicLogin__header__bar ul {
  display: inline-flex;
  grid-gap: 2rem;
  gap: 2rem;
  margin: 0;
  font-size: 1.125rem;
  font-weight: 500;
  color: #5f6368;
  list-style-type: none;
}
.clinicLogin__header__bar ul li a {
  color: #5f6368;
  text-decoration: none;
}
.clinicLogin__header__bar ul li a:hover {
  color: #2b2b2c;
}

.clinicLogin__main {
  margin: 0 auto;
  max-width: 1440px;
}

.clinicLogin__section {
  margin: 60px auto;
}

/* .clinicLogin__grid {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  place-items: center;
} */

.clinicLogin__grid__col--form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clinicLogin__grid__heading {
  font-size: 42px;
  letter-spacing: -0.5px;
  line-height: 56px;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #1a1f36;
}

.clinicLogin__grid__subheading {
  font-size: 25px;
  letter-spacing: initial;
  line-height: 28px;
  color: #6a6e74;
  font-weight: 500;
  margin-top: 8rem;
  margin-bottom: 3rem;
  color: #697386;
  display: flex;
  justify-content: center;
  align-items: center;
}


.clinicLogin__form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clinicLogin__inputField {
  width: 270px !important;
}

.login_input {
  height: 70px;
}

.clinicLogin__editableInputField {
  margin-left: 20px;
}

.clinicLogin__editBtn--wrapper {
  height: 90%;
  position: absolute;
  top: 13px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnWrapper {
  margin-top: 15px;
}

.clinicLogin__submitBtn {
  width: 100% !important;
  color: white;
  border: 0;
  cursor: pointer;
  height: 45px;
  padding: 10px 13px;
  font-size: 16px;
  min-width: 310px;
  background: #088b8c;
  min-height: 30px;
  transition: 0.3s ease all;
  font-weight: bold;
  border-radius: 4px;
  box-shadow: none !important;
  margin-top: 20px;
}

.clinicLogin__submitBtn:hover {
  background: #10a8a9;
}

@media (max-width: 1440px) {
  .clinicLogin__main {
    max-width: 1240px;
  }
}

@media (max-width: 1024px) {
  .clinicLogin__main {
    max-width: 800px;
  }
  .clinicLogin__heroImg {
    left: -40px;
  }
}

@media (max-width: 768px) {
  .clinicLogin__grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 3rem;
    gap: 3rem;
  }
  .clinicLogin__grid__col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .clinicLogin__section {
    margin: 40px 72px;
  }
  .clinicLogin__header__bar ul li a {
    font-size: 0.9rem;
  }
}

@media (max-width: 425px) {
  .clinicLogin__header__bar {
    flex-direction: column;
    padding: 1rem 0rem;
  }
  .clinicLogin__section {
    margin: 20px;
  }
}

.MuiFormHelperText-contained {
  margin-left: 0px !important;
}

.searchBar {
    position: -webkit-sticky;
    position: sticky;
    top: 5%;
    /* width: 245px; */
  }
  @media all and (max-width: 1990px) and (min-width: 1875px) {
    .searchBar {
      width: 228px;
    }
  }
  
  .line {
    border-top: 0 solid;
    border-bottom: 2px solid #EDEDED;
  }
  
  .avatarcontainer {
    display: flex;
  }

  .account__username {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 12px;
    /* color: #4286f7; */
    color: #00a0a0;
    font-weight: 500;
    text-decoration: underline;
    text-underline-offset: 1px;
  }

  .table-row {
    cursor: pointer;
  }

  .notes {
    font-size: 12px;
  }

  .plusIconSize {
    font-size: 65px !important;
    transition: 0.3s ease all;
  }

  .plusIconSize:hover {
    color: #097a7a;
  }

  .image-container {
    height: 700px;
  }

  .image-section {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    height: 100%;
  }

  .img-nodata {
    height: 50%;
    width: 40%;
  }

  .plusIcon {
    z-index: 10;
    position: fixed;
    bottom: 100px;
    right: 102px;
    cursor: pointer;
  }

  .plusIconShrink {
    right: 218px;
    z-index: 10;
    position: fixed;
    bottom: 150px;
    cursor: pointer;
  }

  .notes {
    font-family: sans-serif;
    font-size: 15px;
    padding-left: 71px;
  }

  .notes-cell {
    padding: 5px !important;
  }

  .dataTable .MuiTableContainer-root {
    margin: 0 0 !important;
    /* height: 100% !important; */
  }

  .MuiTableCell-root {
    white-space: nowrap;
  }

  .MuiFormControlLabel-root {
    margin: auto 0 !important;
  }

  .notes-padding {
    display: table-cell;
    padding: 16px;
    font-size: 0.875rem;
    text-align: left;
    /* font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; */
    font-weight: 400;
    line-height: 1.43;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
  }

  .notes-padding-notes {
    display: table-cell;
    padding-top: 0px;
    padding-left: 16px;
    font-size: 0.875rem;
    text-align: left;
    /* font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; */
    font-weight: 400;
    line-height: 1.43;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
  }

  .MuiTableCell-paddingCheckbox {
    width: 48px;
    padding: 0 0 0 4px;
  }

  .checkbox-padding {
    padding-left: 4px;
  }

  .columnFilter {
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .columnList {
    height: 180px;
    overflow: hidden;
  }

  .MuiTablePagination-actions {
    width: 116px !important;
  }

  .pointer:hover {
    cursor: pointer;
  }

  /* .pagination {
    padding-right: 25px !important;
  }

  @media (min-width: 600px) {
    .MuiGrid-grid-sm-10 {
        flex-grow: 0;
        max-width: 100% !important;
        flex-basis: 100% !important;
    }
  } */
.form__container {
  /* border: 2px solid brown; */
  height: 100%;
}

.clinic__form {
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 0rem 1rem 1rem 1rem;
  /* border-right: 2px solid #F2F5FA; */
}

.form--innerWrapper {
  /* max-width: 1024px; */
  /* max-width: 1440px; */
  max-width: 1240px;
  padding: 1px 20px;
}

.formHeading {
  margin-left: 1.2rem;
  padding: 0.1rem;
  font-size: 1.1rem !important;
  font-weight: 500;
}

.inputGrid {
  margin: 0.2rem 1rem;
}

.inputGrid>div {
  padding: 0.5rem !important;
}

.formButtons {
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: center;
  display: flex;
  justify-content: space-around;
}

.centerButtons {
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
}

.navButtons {
  width: 120px;
  color: white;
  align-items: center;
  display: flex;

}

#saveButton.disabled {
  pointer-events: none;
}

.formButtons--wrapper {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: flex-end;
}

.formWrapper {
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: thin;
  padding-right: 5px;
  /* border: 2px solid red; */
  height: 60vh;
}

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

.notesContainer {
  height: calc(100vh - 71px);
  position: relative;
  border-left: 2px solid #F2F5FA;
}

.notesTab__container {
  height: 65%;
  overflow-y: auto;
}

.notesTab__btnContainer {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: flex-end;
}

.notesTab__form {
  margin: 1rem 0;
}

.notesTab__input {
  width: 100% !important;
}

.inputNote {
  position: absolute;
  padding: 20px 2% 30px 0;
  width: 95%;
  bottom: 4%;
}

.note_displayed {
  margin-top: 10px;
}

.clinic__header {
  display: inline-flex;
  width: 100%;
  top: 0;
  z-index: 10;
  background: #fff;
  padding: 0px 50px;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-bottom: 2px solid #F2F5FA;
}

@media (max-width: 1520px) {
  .clinic__header {
    justify-content: space-between;
  }
}

.clinic_header_logo {
  margin-left: -20px;
}

.navButton {
  position: absolute;
  bottom: 146px;
  left: 5px;
}

.navButtonGroup {
  display: flex;
  justify-content: space-between;
}

.clinic__header__nav ul {
  display: flex;
  list-style-type: none;
}

.clinic__header__nav ul li {
  margin: 0px 20px;
  font-size: 18px;
  cursor: pointer;
  position: relative;
  padding: 18px 0px;
  font-weight: 500;
}

.line {
  border-top: 0 solid;
  border-bottom: 2px solid #F2F5FA;
}

.backButton {
  background-color: #FFFFFF !important;
}

.backbuttonWrapper {
  height: 60px;
  background-color: #F2F5FA !important;
  padding: 15px;
  margin: 3px 0 -4px 2px;
}

.clinic__header__nav ul .page__selected:after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 6px;
  background: #14a6a6;
  border-radius: 6px;
}

.iconFont {
  font-size: 40px !important;
}

.clinic__header__logoutBtn {
  margin: 0px 10px !important;
}

.clinic__header__logoutBtn>span {
  padding: 8px;
  color: var(--Secondary-green);
  border-radius: 6px;
  font-size: 0.95em;
  transition: 0.3s ease all;
}

.menu_btn {
  display: flex;
  justify-content: space-between;
  width: 280px;
}

.clinic__header__logoutBtn:hover>span {
  background: var(--Secondary-green);
  color: #fff;
}


/*  */

/* .apptsTab__wrapper {
  border: 1px solid red;
  height: 100%;
} */

.apptsTab {
  height: calc(100vh - 228px);
}

.flex--title {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.apptsTab__header {
  height: 80px;
  width: 100%;
  /* border: 1px solid red; */
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
}

@media (max-width: 1024px) {
  .apptsTab__header {
    height: 100px;
  }
}

.apptsTab__header--title {
  font-size: 1.3em;
  font-weight: 600;
  color: var(--color-grey-900);
}

.apptsTab__header--left {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
}

.apptsTab__header--right {
  grid-gap: 0.1rem;
  gap: 0.1rem;
  display: flex;
}

.apptsTab__form {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 12px;
}

.appsTab__container {
  height: 100%;
}

/* ********** */
.appListReport {
  height: 100%;
  box-shadow: none !important;
}

.appListReport--wrapper {
  height: 100%;
  overflow-y: hidden;
  padding: 1.5rem;
}

.appListReport__heading {
  padding-bottom: 1rem;
  color: #000;
  font-weight: 500 !important;
  font-size: 1.5em !important;
}

.appListReport__filterSection {
  border: 1px solid var(--Grey5);
  border-radius: 6px;
  margin-bottom: 1rem;
}

.appListReport__filterSection--wrapper {
  padding: 0.2rem 1rem;
  display: flex;
  justify-content: space-between;
}

.appListReport__filterSection__one {
  display: flex;
}

.appListReport__filterSection__two {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  position: relative;
  /* margin-top: 10px; */
}

.appListReport__singleDropdown {
  display: inline-flex;
  align-items: center;
}

.appListReport__filterBtn {
  padding: 6px 10px;
  box-shadow: none !important;
}

.appListReport__dateSelect {
  display: flex;
  align-items: center;
  transition: 0.3s ease all;
  border-radius: 6px;
  padding-right: 10px;
  cursor: pointer;
  max-width: 200px;
  width: 160px;
}

/* .appListReport__dateSelect:hover {
  background: rgba(0, 0, 0, 0.05);
} */

.appListReport__dateText {
  cursor: pointer;
  color: var(--Grey2);
  transition: 0.3s ease all;
}

/* .appListReport__dateText:hover {
  color: var(--Grey1);
} */
.appListReport__dateRangePicker {
  position: absolute;
  right: 700px;
  z-index: 100;
  /* top: 70px;  */
}

/* **************** */
.statusChip {
  /* font-size: 0.9em; */
  padding: 5px 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.status--confirmed {
  color: #439579;
  background: #eaf9f5;
}

.status--canceled {
  color: #ec567b;
  background: #fff3f8;
}

.status--rescheduled {
  color: #d1ba00;
  background: #fff2d7;
}

.status--noresponse {
  color: #ce7cff;
  background: #f9efff;
}

.status--optout {
  color: #f57850;
  background: #ffeae3;
}

.patient__collapsible {
  padding: 1rem;
}

.formBtnContainer {
  display: flex;
  padding: 1rem 0rem;
  justify-content: flex-start;
  grid-gap: 1rem;
  gap: 1rem;
}

.patient__customInputField .label {
  font-size: 0.9rem;
  color: #697386;
}

.patient__customInputField .field {
  color: var(--color-grey-900);
  font-weight: 500;
}

.moreOptionsPopover {
  padding: 0.6rem;
  border-radius: 12px;
}

.appt__paginationRoot {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
  margin-top: 1rem;
  border-radius: 12px;
  /* box-shadow: rgb(0 0 0 / 5%) 0px 6px 24px 0px, rgb(0 0 0 / 8%) 0px 0px 0px 1px; */
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 24px 0px, rgb(0 0 0 / 8%) 0px 0px 0px 1px;
}


.uploadCsvDialog__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
}

.icon {
  padding: 1rem;
  height: 7.5rem;

  /* width: 2rem; */
}

.icon:hover {
  cursor: pointer;
}

.uploadCsvDialog__uploadArea {
  padding: 2rem;
}

.uploadCsvDialog__dashedArea {
  display: flex;
  flex-direction: column;
  grid-gap: 0.7rem;
  gap: 0.7rem;
  justify-content: center;
  align-items: center;
  border: 2px dashed #e5e5e5;
  padding: 1.5rem;
  background: rgba(208, 239, 239, 0.27);
  border-radius: 6px;
}

.uploadCsvDialog__btnWrapper {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  align-items: center;
}

.updateBar {
  /* width: 40%; */
  /* border: 2px solid red; */
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  /* border: 2px solid red; */
  padding: 2px;
}

.statusChange {
  min-width: 100px;
}

.marketingCampaigns {
  min-width: 100px;
  margin-left: 10px;
  margin-right: 10px;
}

.updateButton {
  margin: 10px;
}

.fileList {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;

  padding: 1rem 0;
}

.fileList__item {
  display: flex;
  padding: 0.6rem;
  border: 1px solid var(--Grey5);
  border-radius: 8px;
  align-items: center;
}

.fileList__item img {
  cursor: pointer;
}

.fileList__itemWrapper {
  display: flex;
}

.uploadCsvDialog__dashedArea .link--text {
  font-weight: 600;
  font-size: 1.1rem;
  color: var(--Secondary-green--dark);
}

*/ .nodata {
  display: flex;
  justify-content: center;
}

.text-nodata {
  display: flex;
  justify-content: center;
  color: #697386;
}

.nodata .img-nodata {
  display: flex;
  justify-content: center;
  /* margin: 3rem 0 0; */
  /* width: 50%; */
}

.nodata .img-nodata .img {
  display: flex;
  justify-content: center;
  max-width: 10%;
  /* margin: 3rem 0 0; */
  /* width: 50%; */
}

.stepper {
  padding-top: 4rem !important;
}

.stepper_button_wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 4rem 1rem 1rem;
}




/*  */


@media (max-width: 1440px) and (min-width: 900px) {
  .clinicHeader .MuiTypography-h4 {
    font-size: 1.75rem;
  }

  .iconFont {
    font-size: 32px !important;
  }
}
