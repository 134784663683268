.clinic__header {
  display: inline-flex;
  width: 100%;
  top: 0;
  z-index: 10;
  background: #fff;
  padding: 0px 50px;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-bottom: 2px solid #F2F5FA;
}

@media (max-width: 1520px) {
  .clinic__header {
    justify-content: space-between;
  }
}

.clinic_header_logo {
  margin-left: -20px;
}

.navButton {
  position: absolute;
  bottom: 146px;
  left: 5px;
}

.navButtonGroup {
  display: flex;
  justify-content: space-between;
}

.clinic__header__nav ul {
  display: flex;
  list-style-type: none;
}

.clinic__header__nav ul li {
  margin: 0px 20px;
  font-size: 18px;
  cursor: pointer;
  position: relative;
  padding: 18px 0px;
  font-weight: 500;
}

.line {
  border-top: 0 solid;
  border-bottom: 2px solid #F2F5FA;
}

.backButton {
  background-color: #FFFFFF !important;
}

.backbuttonWrapper {
  height: 60px;
  background-color: #F2F5FA !important;
  padding: 15px;
  margin: 3px 0 -4px 2px;
}

.clinic__header__nav ul .page__selected:after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 6px;
  background: #14a6a6;
  border-radius: 6px;
}

.iconFont {
  font-size: 40px !important;
}

.clinic__header__logoutBtn {
  margin: 0px 10px !important;
}

.clinic__header__logoutBtn>span {
  padding: 8px;
  color: var(--Secondary-green);
  border-radius: 6px;
  font-size: 0.95em;
  transition: 0.3s ease all;
}

.menu_btn {
  display: flex;
  justify-content: space-between;
  width: 280px;
}

.clinic__header__logoutBtn:hover>span {
  background: var(--Secondary-green);
  color: #fff;
}


/*  */

/* .apptsTab__wrapper {
  border: 1px solid red;
  height: 100%;
} */

.apptsTab {
  height: calc(100vh - 228px);
}

.flex--title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.apptsTab__header {
  height: 80px;
  width: 100%;
  /* border: 1px solid red; */
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
}

@media (max-width: 1024px) {
  .apptsTab__header {
    height: 100px;
  }
}

.apptsTab__header--title {
  font-size: 1.3em;
  font-weight: 600;
  color: var(--color-grey-900);
}

.apptsTab__header--left {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.apptsTab__header--right {
  gap: 0.1rem;
  display: flex;
}

.apptsTab__form {
  display: flex;
  gap: 1rem;
  margin-bottom: 12px;
}

.appsTab__container {
  height: 100%;
}

/* ********** */
.appListReport {
  height: 100%;
  box-shadow: none !important;
}

.appListReport--wrapper {
  height: 100%;
  overflow-y: hidden;
  padding: 1.5rem;
}

.appListReport__heading {
  padding-bottom: 1rem;
  color: #000;
  font-weight: 500 !important;
  font-size: 1.5em !important;
}

.appListReport__filterSection {
  border: 1px solid var(--Grey5);
  border-radius: 6px;
  margin-bottom: 1rem;
}

.appListReport__filterSection--wrapper {
  padding: 0.2rem 1rem;
  display: flex;
  justify-content: space-between;
}

.appListReport__filterSection__one {
  display: flex;
}

.appListReport__filterSection__two {
  display: flex;
  gap: 1rem;
  position: relative;
  /* margin-top: 10px; */
}

.appListReport__singleDropdown {
  display: inline-flex;
  align-items: center;
}

.appListReport__filterBtn {
  padding: 6px 10px;
  box-shadow: none !important;
}

.appListReport__dateSelect {
  display: flex;
  align-items: center;
  transition: 0.3s ease all;
  border-radius: 6px;
  padding-right: 10px;
  cursor: pointer;
  max-width: 200px;
  width: 160px;
}

/* .appListReport__dateSelect:hover {
  background: rgba(0, 0, 0, 0.05);
} */

.appListReport__dateText {
  cursor: pointer;
  color: var(--Grey2);
  transition: 0.3s ease all;
}

/* .appListReport__dateText:hover {
  color: var(--Grey1);
} */
.appListReport__dateRangePicker {
  position: absolute;
  right: 700px;
  z-index: 100;
  /* top: 70px;  */
}

/* **************** */
.statusChip {
  /* font-size: 0.9em; */
  padding: 5px 12px;
  width: fit-content;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.status--confirmed {
  color: #439579;
  background: #eaf9f5;
}

.status--canceled {
  color: #ec567b;
  background: #fff3f8;
}

.status--rescheduled {
  color: #d1ba00;
  background: #fff2d7;
}

.status--noresponse {
  color: #ce7cff;
  background: #f9efff;
}

.status--optout {
  color: #f57850;
  background: #ffeae3;
}

.patient__collapsible {
  padding: 1rem;
}

.formBtnContainer {
  display: flex;
  padding: 1rem 0rem;
  justify-content: flex-start;
  gap: 1rem;
}

.patient__customInputField .label {
  font-size: 0.9rem;
  color: #697386;
}

.patient__customInputField .field {
  color: var(--color-grey-900);
  font-weight: 500;
}

.moreOptionsPopover {
  padding: 0.6rem;
  border-radius: 12px;
}

.appt__paginationRoot {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
  margin-top: 1rem;
  border-radius: 12px;
  /* box-shadow: rgb(0 0 0 / 5%) 0px 6px 24px 0px, rgb(0 0 0 / 8%) 0px 0px 0px 1px; */
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 24px 0px, rgb(0 0 0 / 8%) 0px 0px 0px 1px;
}


.uploadCsvDialog__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
}

.icon {
  padding: 1rem;
  height: 7.5rem;

  /* width: 2rem; */
}

.icon:hover {
  cursor: pointer;
}

.uploadCsvDialog__uploadArea {
  padding: 2rem;
}

.uploadCsvDialog__dashedArea {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  justify-content: center;
  align-items: center;
  border: 2px dashed #e5e5e5;
  padding: 1.5rem;
  background: rgba(208, 239, 239, 0.27);
  border-radius: 6px;
}

.uploadCsvDialog__btnWrapper {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  align-items: center;
}

.updateBar {
  /* width: 40%; */
  /* border: 2px solid red; */
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  /* border: 2px solid red; */
  padding: 2px;
}

.statusChange {
  min-width: 100px;
}

.marketingCampaigns {
  min-width: 100px;
  margin-left: 10px;
  margin-right: 10px;
}

.updateButton {
  margin: 10px;
}

.fileList {
  display: flex;
  gap: 1rem;

  padding: 1rem 0;
}

.fileList__item {
  display: flex;
  padding: 0.6rem;
  border: 1px solid var(--Grey5);
  border-radius: 8px;
  align-items: center;
}

.fileList__item img {
  cursor: pointer;
}

.fileList__itemWrapper {
  display: flex;
}

.uploadCsvDialog__dashedArea .link--text {
  font-weight: 600;
  font-size: 1.1rem;
  color: var(--Secondary-green--dark);
}

*/ .nodata {
  display: flex;
  justify-content: center;
}

.text-nodata {
  display: flex;
  justify-content: center;
  color: #697386;
}

.nodata .img-nodata {
  display: flex;
  justify-content: center;
  /* margin: 3rem 0 0; */
  /* width: 50%; */
}

.nodata .img-nodata .img {
  display: flex;
  justify-content: center;
  max-width: 10%;
  /* margin: 3rem 0 0; */
  /* width: 50%; */
}

.stepper {
  padding-top: 4rem !important;
}

.stepper_button_wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 4rem 1rem 1rem;
}




/*  */


@media (max-width: 1440px) and (min-width: 900px) {
  .clinicHeader .MuiTypography-h4 {
    font-size: 1.75rem;
  }

  .iconFont {
    font-size: 32px !important;
  }
}